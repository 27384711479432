import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { isRouteActive } from '@lib/route/checkRoute';
import { SxStyles } from '@lib/theme/sxTheme';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { Box, IconButton } from '@mui/material';
import { TextLink } from '@ui/buttons/TextLink';

interface Props {
  title: string;
  isOpen?: boolean;
  hasLevels?: boolean;
  href?: string;
  target?: string;
  activeColor?: string;
  onClick?: () => void;
  onDropdownToggle?: () => void;
}

export const MenuItem: FC<Props> = ({
  hasLevels = false,
  isOpen,
  href,
  title,
  target,
  activeColor,
  onClick,
  onDropdownToggle,
}) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const isActive = isRouteActive(pathname, href);
  const dynamicStyles = styles({
    activeColor,
  });

  return (
    <Box sx={dynamicStyles.getValue('menuItem')}>
      {href ? (
        <TextLink
          href={href}
          typographyProps={{
            sx: dynamicStyles.merge('menuItemText', isActive && 'active'),
          }}
          underline="none"
          color="inherit"
          target={target}
          onClick={onClick}
        >
          {title}
        </TextLink>
      ) : (
        <Box sx={dynamicStyles.getValue('menuItemText')} onClick={onClick}>
          {title}
        </Box>
      )}
      {hasLevels && (
        <IconButton
          size="small"
          aria-label={t('common:ariaLabel.toggleDropdown')}
          onClick={onDropdownToggle}
          sx={dynamicStyles.getValue('icon')}
        >
          {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </IconButton>
      )}
    </Box>
  );
};

interface StyleProps {
  activeColor?: string;
}

const styles = ({ activeColor }: StyleProps) =>
  new SxStyles({
    menuItem: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      pt: 1,
      pb: 1,
    },
    menuItemText: {
      fontSize: 16,
      fontWeight: 'fontWeightRegular',
      cursor: 'pointer',
      pt: 1,
      pb: 1,
    },
    icon: {
      color: 'inherit',
      display: 'flex',
      alignItems: 'center',
      p: 0,
    },
    active: {
      color: activeColor ?? 'primary.main',

      '& .MuiSvgIcon-root': {
        color: activeColor ?? 'primary.main',
      },
    },
  });
